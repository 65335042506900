import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';
import { useSWRConfig } from 'swr';

import Loading from '@/components/utility/Loading';
import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import usePagedAdverts from '@/hooks/adverts/usePagedAdverts';
import useAccountPlaylistPitch from '@/hooks/playlist/useAccountPlaylistPitches';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { SurveyQueryParams } from '@/models/Enums';
import { PaymentConfirmationDataModel } from '@/models/Payments';

const PaymentConfirmationPage = ({ productType }: { productType?: string }) => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { mutate } = useSWRConfig();
  const { refetchPagedAdverts } = usePagedAdverts({
    status: '',
    offset: 0,
    limit: 10,
  });
  const { refetchAccountPlaylistPitch } = useAccountPlaylistPitch({});
  const { breakpointHit } = useBreakpoints();

  const { t } = useTranslation();

  const { isMonthly, isSubscribed } = useSubscription();

  const [data, setData] = useState<PaymentConfirmationDataModel>();

  const playlistPitchData = useMemo(
    () => ({
      title: 'PAYMENTS.PLAYLIST-PITCH-CONFIRMATION-TITLE',
      instructions: [
        { icon: 'headphones', text: 'PAYMENTS.PLAYLIST-PITCH-INSTRUCTIONS-1' },
        {
          icon: 'notifications',
          text: 'PAYMENTS.PLAYLIST-PITCH-INSTRUCTIONS-2',
        },
        {
          icon: 'chat',
          text: 'PAYMENTS.PLAYLIST-PITCH-INSTRUCTIONS-3',
        },
      ],
      link: `/playlisting?${SurveyQueryParams.PITCHING_SURVEY}=true`,
      tabNumber: 1,
    }),
    []
  );

  const tikTokAdData = useMemo(
    () => ({
      title: 'PAYMENTS.TIKTOK-ADS-CONFIRMATION-TITLE',
      instructions: [
        {
          icon: 'visibility',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-1',
        },
        {
          icon: 'local_fire_department',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-2',
        },
        {
          icon: 'bar_chart',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-3',
        },
      ],
      link: '/social-ads',
      tabNumber: 0,
    }),
    []
  );

  const metaAdData = useMemo(
    () => ({
      title: 'PAYMENTS.META-ADS-CONFIRMATION-TITLE',
      instructions: [
        {
          icon: 'visibility',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-1',
        },
        {
          icon: 'local_fire_department',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-2',
        },
        {
          icon: 'bar_chart',
          text: 'PAYMENTS.SOCIAL-MEDIA-INSTRUCTIONS-3',
        },
      ],
      link: '/social-ads',
      tabNumber: 0,
    }),
    []
  );

  const subscriptionData = useMemo(
    () => ({
      title: 'PAYMENTS.SUBSCRIPTION-CONFIRMATION-TITLE',
      link: '/',
      tabNumber: 0,
    }),
    []
  );

  const releaseCycleData = useMemo(
    () => ({
      title: breakpointHit ? 'CONGRATS!!' : 'CONGRATULATIONS!!',
      link: '/release-cycles',
      tabNumber: 0,
    }),
    [breakpointHit]
  );

  useEffect(() => {
    if (productType === 'playlist') {
      setData(playlistPitchData);
      refetchAccountPlaylistPitch();
    } else if (productType === 'meta') {
      setData(metaAdData);
      refetchPagedAdverts();
    } else if (productType === 'tiktok') {
      setData(tikTokAdData);
      refetchPagedAdverts();
    } else if (productType === 'subscription') {
      setData(subscriptionData);
      mutate(() => true, undefined, { revalidate: false });
    } else if (productType === 'release-cycle') {
      setData(releaseCycleData);
    }
  }, [
    metaAdData,
    mutate,
    params,
    playlistPitchData,
    productType,
    refetchAccountPlaylistPitch,
    refetchPagedAdverts,
    releaseCycleData,
    setSearchParams,
    subscriptionData,
    tikTokAdData,
  ]);

  if (!data) return <Loading size="small" />;

  return (
    <div className="payment-confirmation-screen">
      <div className={`circle ${productType === 'subscription' ? 'subscription' : ''}`}></div>
      <div className="ml-auto mr-auto">
        <h1 className="mt62" data-testid={data?.title}>
          {t(data?.title)}
        </h1>
        <img
          className="mt20"
          height={100}
          src={productType === 'release-cycle' ? Images.partyEmoji : Images.largeTick}
          alt=""
        />
        <div className="mt100 w100p">
          {productType !== 'subscription' &&
            data?.instructions?.map((item) => (
              <div className="card p16 mb8 max-w500 ml-auto mr-auto d-flex" key={item.text}>
                <div className="mt-auto mb-auto">
                  <Icon className="material-symbols-outlined">{item.icon}</Icon>
                </div>
                <p className="text-left pl16">{t(item.text)}</p>
              </div>
            ))}
          {productType === 'subscription' && (
            <>
              <p className="mt20">{t('PAYMENTS.SUBSCRIPTION-INSTRUCTIONS-1')}</p>
              <p className="text-faded mt20">
                {isMonthly
                  ? t('PAYMENTS.SUBSCRIPTION-INSTRUCTIONS-MONTHLY')
                  : isSubscribed
                    ? t('PAYMENTS.SUBSCRIPTION-INSTRUCTIONS-ANNUAL')
                    : t('PAYMENTS.SUBSCRIPTION-INSTRUCTIONS-FALLBACK')}
              </p>
            </>
          )}
          {productType === 'release-cycle' && (
            <p className="mt100 max-w500 ml-auto mr-auto">
              Congratulations on completing your release cycle! Take a moment to celebrate this significant milestone in
              your music career. Now, relax, unwind, and savour the rewards of your hard work and dedication.
            </p>
          )}
        </div>
        <Button
          className="btn-white min-w180 mt48"
          onClick={async () => {
            if (productType === 'playlist') await refetchAccountPlaylistPitch();
            if (productType === 'meta' || productType === 'tiktok') {
              await refetchPagedAdverts();
            }
            if (productType === 'subscription') {
              mutate(() => true, undefined, { revalidate: false });
              return (window.location.href = window.location.origin);
            }
            navigate(data?.link, { state: data?.tabNumber });
          }}
        >
          {t('COMMON.DONE')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentConfirmationPage;
